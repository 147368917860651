import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import ImageFeed from '../components/ImageFeed'

const addHash = ({ heading, description }) => ({
  heading,
  description,
  hash: `#${heading.toLowerCase().replace(/[^\w\s]/g, '').split(' ').join('-')}`,
});

const isSelected = (anchor, hash) => anchor === hash;

const SectionItem = (path, activeHash) => ({ heading, description, hash }) => {
  return (
    <>
      <li className={`section-item${isSelected(activeHash, hash) ? ' selected' : ''}`}>
        <Link to={`${path}${hash}`}>
          <h4>{heading}</h4>
        </Link>
        <p>{description}</p>
      </li>
    </>
  );
};

const SectionList = ({ sections, path, activeHash }) => {
  if (sections && sections.length) {
    return <ul>{sections.map(SectionItem(path, activeHash))}</ul>;
  }

  return null;
};

const SingleTag = (tag) => (
  <li key={tag + `tag`} className="tag">
    <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
  </li>
);

const TagList = ({ tags }) => {
  console.log('tags: ', tags);
  if (tags && tags.length) {
    return <ul className="taglist">{tags.map(SingleTag)}</ul>;
  }

  return null;
};


export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  gallery,
  isCms,
  sections,
  path,
  activeHash,
}) => {
  const [isRightFixed, setRightFixed] = useState(false);

  const PostContent = contentComponent || Content;

  const rightContainer = useRef(null);
  const bannerImage = useRef(null);
  const imageFeed = useRef(null);

  const handleScroll = () => {
    const imageFeedDimensions = imageFeed?.current?.getBoundingClientRect();
    const bannerImageDimensions = bannerImage?.current?.getBoundingClientRect();
    const rightContainerDimensions = rightContainer?.current?.getBoundingClientRect();
    if (
      imageFeedDimensions?.top < rightContainerDimensions.height ||
      bannerImageDimensions.bottom > 0
    ) {
      setRightFixed(false);
    }

    else if (bannerImageDimensions.bottom < 0) {
      setRightFixed(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    const script = document.createElement('script');
    script.src = "https://unpkg.com/masonry-layout@4/dist/masonry.pkgd.min.js"
    script.id = "masonry"
    window.document.head.appendChild(script);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      const masonry = window.document.getElementById('masonry');
      masonry.parentNode.removeChild(masonry);
    };
  }, [isRightFixed]);

  return (
    <div className="blog-post">
      <div className="banner-image" ref={bannerImage}>
        my adventures
      </div>

      <section className="section">
        {helmet || ''}
        <div className="container content">
          <div className="columns">
            <div className="column is-10 content-container">
              <div className="post-content">
                <PostContent content={content} />
                <div className={`right-container${isRightFixed ? ' fixed' : ''}`} ref={rightContainer}>
                  <h1 className="title">{title}</h1>
                  <p>{description}</p>
                  <SectionList activeHash={activeHash} path={path} sections={sections} />
                  <TagList tags={tags} />
                </div>
              </div>

              {gallery && !!gallery.length && (
                <div ref={imageFeed}>
                  <ImageFeed gallery={gallery} isCms={isCms} />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data, path, location }) => {
  const { markdownRemark: post } = data;
  const { title, description, tags, gallery, sections } = post.frontmatter;

  const sectionsWithHash = sections ? sections.map(addHash) : [];

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${title}`}</title>
            <meta
              name="description"
              content={`${description}`}
            />
          </Helmet>
        }
        sections={sectionsWithHash}
        tags={tags}
        title={title}
        gallery={gallery}
        path={path}
        activeHash={location.hash}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        tags: PropTypes.array,
        gallery: PropTypes.array,
        sections: PropTypes.array,
      }),
      html: PropTypes.string.isRequired,
    }),
  }),
};

BlogPostTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.array,
  title: PropTypes.string,
  helmet: PropTypes.string,
  gallery: PropTypes.array,
  isCms: PropTypes.bool,
  sections: PropTypes.array,
  path: PropTypes.string,
  activeHash: PropTypes.string,
};

BlogPostTemplate.defaultProps = {
  tags: [],
  gallery: [],
  isCms: false,
  sections: [],
  path: '',
  hash: '',
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        gallery {
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sections {
          heading
          description
        }
        tags
      }
    }
  }
`
