import React, { useEffect } from 'react';
import Masonry from 'react-masonry-component';
import PropTypes from 'prop-types';

const masonryOptions = {
  gutter: 5,
  percentPosition: true,
};

const SingleImage = ({ image = {} }, idx) => (
  <div className="grid-item" key={`img-${idx}`}>
    <img
      src={`${image && image.childImageSharp ? image.childImageSharp.fluid.src : image}`}
      alt="imagefeed"
    />
  </div>
);

const ImageFeed = ({ gallery }) => {
  useEffect(() => {})

  if (!gallery.length) return null;

  return (
    <Masonry
      className="grid"
      elementType="div"
      options={masonryOptions}
    >
      {gallery.map(SingleImage)}
    </Masonry>
  );
};

ImageFeed.propTypes = {
  gallery: PropTypes.array,
};

ImageFeed.defaultProps = {
  gallery: [],
};

export default ImageFeed;
